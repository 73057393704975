@use "styles/colours";
@use "styles/spacing";

.grid__table-footer {
    background-color: colours.$background-tertiary;
    padding: spacing.$spacing-small;
    display: flex;
    justify-content: flex-end;
    box-shadow: inset 0 1px 2px -1px rgba(black, 0.1);
}
