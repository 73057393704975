@use "styles/spacing";

.assignment-details {
    .details-section-title {
        padding-bottom: spacing.$spacing-small;
        button {
            margin-top: 0;
        }
    }
    &__no-assignments {
        margin-left: spacing.$spacing-large;
        word-break: break-word;
    }
}
