@use "styles/colours";
@use "styles/spacing";
@use "styles/variables";

.grid__heading {
    position: relative;
    line-height: variables.$line-height-base;

    div {
        display: inline-flex;
        align-items: center;
    }

    svg {
        transition: color 150ms ease-out;
        margin-left: spacing.$spacing-xx-small;
        color: rgba(colours.$label, 0.5);
    }

    &:not(.grid__heading--is-filtered):hover > div > svg {
        color: colours.$primary;
    }

    &--has-menu {
        cursor: pointer;
    }

    thead th &--is-filtered {
        color: colours.$primary;
    }

    > div {
        transition: all 250ms ease-out;
    }

    &--is-filtered > div {
        background-color: lighten(colours.$positive, 50%);
        outline: 8px solid lighten(colours.$positive, 50%);
        color: colours.$positive;
        border-radius: 1px;

        svg {
            color: colours.$positive;
        }
    }

    &--is-filtered:hover > div {
        background-color: lighten(colours.$positive, 40%);
        outline: 8px solid lighten(colours.$positive, 40%);
    }
}

.grid__menu {
    padding: spacing.$spacing-base;
    background-color: colours.$background;
    box-shadow: variables.$box-box-shadow, 0 5px 20px rgba(black, 0.3);
    border-radius: 4px;
}
