@use "@edgetier/client-components/dist/styles/colours";
@use "@edgetier/client-components/dist/styles/spacing";

.select-list-title {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    padding: spacing.$spacing-x-large spacing.$spacing-large;
    text-align: center;
    border: 1px solid colours.$divider;
    border-bottom: none;
    box-shadow: inset 0 1px 3px rgba(black, 0.05);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    svg {
        color: colours.$label;
        margin-left: spacing.$spacing-xx-small;
        font-size: 12px;
    }
}
