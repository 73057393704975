////
/// Spacing defaults for all apps and component libraries
/// @group Spacing
////

$spacing-increment: 2px !default;
$spacing-xxx-small: $spacing-increment !default;
$spacing-xx-small: 2 * $spacing-increment !default;
$spacing-x-small: 3 * $spacing-increment !default;
$spacing-small: 4 * $spacing-increment !default;
$spacing-base: 5 * $spacing-increment !default;
$spacing-large: 6 * $spacing-increment !default;
$spacing-x-large: 8 * $spacing-increment !default;
$spacing-xx-large: 2 * $spacing-x-large !default;
$spacing-xxx-large: 3 * $spacing-x-large !default;

// Padding for all form fields.
$field-padding: $spacing-large !default;
