@use "styles/colours";
@use "styles/spacing";

.grid__menu-item {
    all: unset; // clear any default browser styling
    display: block;
    padding: 10px;
    cursor: pointer;
    transition: all 250ms ease-out;
    border-radius: 4px;

    &:not(.grid__menu-item--is-active):hover {
        background-color: colours.$background-secondary;
    }

    &--is-active {
        background-color: lighten(colours.$primary, 40%);
    }

    & + & {
        margin-top: spacing.$spacing-xxx-small;
    }
}
