@use "styles/theme";

.query-history-item {
    flex: 1;
    border-radius: 5px;

    @include theme.theme {
        border-top: 3px solid theme.get(background-secondary);
        border-bottom: 3px solid theme.get(background-secondary);
        background-color: theme.get(background-secondary);
    }
}
