/*
  Mixin for applying ellipsis to any element
*/
.box-subheading {
  font-size: 1.1rem;
  margin-top: 6px;
  color: #667273;
  font-weight: normal;
}

