@use "styles/spacing";

.chat-variable-fields {
    &__title-group {
        display: flex;
        flex-direction: row;
        gap: spacing.$spacing-base;
    }
    .details-field-value,
    .details-field-title span {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}
