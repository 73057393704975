@use "@edgetier/client-components/dist/styles/colours";
@use "@edgetier/client-components/dist/styles/spacing";
@use "../styles/variables";

@mixin modal($width) {
    width: $width;
    transition: width 250ms ease-out, height 250ms linear;
}

.modal {
    padding: variables.$modal-padding;
    @include modal(500px);
    box-shadow: 0 10px 200px rgba(colours.$flat-midnight-blue, 0.5);
    border-radius: 2px;
    opacity: 1;
    transform: scale3d(1, 1, 1);
    animation-duration: 0.45s;
    animation-play-state: running;
    animation-direction: reverse;
    animation-name: appear-up;

    background-color: colours.$background;

    &:focus {
        outline: none;
    }

    &__outer:focus {
        outline: none;
    }

    &__overlay {
        -webkit-overflow-scrolling: touch;
        animation: appear 250ms linear;
        overflow-y: hidden;
        position: absolute;
        display: flex;
        top: 0;
        right: 0;
        left: 0;
        min-height: 100vh;
        background-color: rgba(black, 0.45);

        align-items: center;
        justify-content: center;

        .modal {
            transform-origin: center;
        }
    }

    &-wide {
        @include modal(720px);
    }

    &-widest {
        @include modal(1000px);
    }

    &-super-wide {
        @include modal(1200px);
    }

    .modal-content {
        form {
            display: flex;
            flex-direction: column;
            margin-top: spacing.$spacing-x-large;
            margin-left: -#{variables.$modal-padding};
            margin-right: -#{variables.$modal-padding};
            padding: 0 variables.$modal-padding;
            padding-top: variables.$modal-padding;

            background-color: colours.$background-secondary;

            button[type="submit"],
            .button--split--submit {
                align-self: flex-end;
                margin: calc(variables.$modal-padding * 0.5) 0;
            }
        }
    }
}

@keyframes appear-up {
    to {
        opacity: 0;
        transform: scale3d(0.85, 0.85, 1);
    }
}
