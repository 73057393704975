@use "styles/theme";
@use "styles/variables";

.button--split {
    button {
        margin-top: 0px !important;
        border-radius: 3px 0px 0px 3px;
    }
    &__no-dropdown-options {
        button {
            border-radius: 3px;
        }
    }
    &__container {
        display: flex;
        align-items: center;
    }
    &__drop-down-only {
        button {
            cursor: unset !important;
        }
        button[disabled] {
            @include theme.theme {
                border-top: 1px solid theme.get(primary);
                border-bottom: 1px solid theme.get(primary);
                border-left: 1px solid theme.get(primary);
                color: theme.get(primary);
                background-color: theme.get(background);
            }
        }
    }

    .drop-down-menu {
        button {
            all: unset;
            text-transform: uppercase;
            font-size: variables.$font-size-small;
        }
        color: white;
        border: none;
        border-left: 1px solid rgba(black, 0.1);
        border-radius: 0px 3px 3px 0px;

        @include theme.theme {
            &:not(.button--split-disabled):not([disabled]) {
                background-color: theme.get(primary);
            }
            &:not(.button--split-disabled):not([disabled]):hover {
                div {
                    background-color: theme.get(primary-variant);
                }
            }
        }
        div:after {
            margin: 0;
        }
        div {
            height: variables.$button-height;
        }
        li,
        div,
        button {
            line-height: variables.$button-height;
            font-weight: bold;
        }
        ul {
            margin-top: 1px;
        }
        ul,
        li {
            border-radius: 3px;
        }

        &__icon {
            margin-left: 0;
        }
    }

    &--negative {
        .drop-down-menu {
            @include theme.theme {
                &:not(.button--split-disabled):not([disabled]) {
                    background-color: theme.get(negative);
                }
                &:not(.button--split-disabled):not([disabled]):hover {
                    div {
                        background-color: theme.get(negative-variant);
                    }
                }
            }
        }
    }
}
