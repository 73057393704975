.grid__row--active {
  outline: 1px solid #16a085;
}
.grid__row--active,
.grid__row--active td {
  transition: all 150ms ease-out;
}
.grid__row--active td {
  background-color: rgba(22, 160, 133, 0.075);
}
.grid__row--is-clickable {
  cursor: pointer;
}
.grid__row--fixed {
  font-weight: bold;
  color: #3498db;
}
.grid__row--fixed + tr:not(.grid__row--fixed) {
  border-top: 1px solid rgba(52, 152, 219, 0.3);
}
.grid__row--warning {
  background-color: #fbdedb;
}
.grid__row--warning:hover {
  background-color: #f9d1cd;
}

.grid tbody tr:not(.grid__empty):not(.grid__row--is-loading).grid__row--highlighted {
  background-color: #f3f9fd;
}
.grid tbody tr:not(.grid__empty):not(.grid__row--is-loading).grid__row--highlighted:hover {
  background-color: #e6f2fa;
}
.grid tbody tr:not(.grid__empty):not(.grid__row--is-loading).grid__row--highlighted:last-of-type {
  border-bottom: 2px solid #e74c3c;
}

