@use "styles/colours";
@use "styles/spacing";

.tooltip-warning {
    $size: 24px;

    &__content {
        padding: 0 spacing.$spacing-base;
        border-radius: 4px;
        white-space: nowrap;
        font-weight: bold;
        &:first-letter {
            text-transform: uppercase;
        }
    }

    &__icon {
        height: $size;
        width: $size;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
    }

    &__icon,
    &__content {
        background-color: lighten(colours.$negative, 35%);
        color: colours.$negative;
        border: 1px solid colours.$negative;
        &:hover {
            background-color: lighten(colours.$negative, 30%);
        }

        ul {
            max-width: 300px;
            svg {
                margin-right: spacing.$spacing-small;
            }
            li {
                word-wrap: break-word;
                white-space: normal;
            }
        }
        &__warning {
            display: flex;
            margin: spacing.$spacing-base 0;
            align-items: center;
        }
    }
}
