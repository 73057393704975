/*
  Mixin for applying ellipsis to any element
*/
.box-header {
  margin-top: 16px;
  margin-right: 16px;
  margin-left: 16px;
  padding: 16px;
  background-color: #f2f5f5;
  border-bottom: 1px solid #ecf0f1;
  position: relative;
  border-radius: 6px;
}

