.toggle {
  align-items: center;
  display: flex;
  flex-shrink: 0;
}
.toggle__state {
  padding-left: 6px;
  display: flex;
  justify-content: flex-end;
}
.toggle__state > div:nth-of-type(2) {
  margin-left: -50%;
}
.toggle__state--disabled {
  padding-left: 0;
  padding-right: 6px;
}
.toggle__label {
  display: inline-block;
  margin-left: 8px;
}
.theme-dark .toggle .react-toggle-thumb {
  background-color: #34495e;
  border: 3px solid #95a5a6;
}
.theme-light .toggle .react-toggle-thumb {
  background-color: white;
  border: 3px solid #7f8c8d;
}
.theme-contrast .toggle .react-toggle-thumb {
  background-color: white;
  border: 3px solid #5a6465;
}
.theme-dark .toggle .react-toggle--checked .react-toggle-thumb {
  border: 3px solid #16a085;
}
.theme-light .toggle .react-toggle--checked .react-toggle-thumb {
  border: 3px solid #16a085;
}
.theme-contrast .toggle .react-toggle--checked .react-toggle-thumb {
  border: 3px solid #16a085;
}
.theme-dark .toggle .react-toggle-track,
.theme-dark .toggle .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #95a5a6;
}
.theme-light .toggle .react-toggle-track,
.theme-light .toggle .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #7f8c8d;
}
.theme-contrast .toggle .react-toggle-track,
.theme-contrast .toggle .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #5a6465;
}
.theme-dark .toggle .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track,
.theme-dark .toggle .react-toggle--checked .react-toggle-track {
  background-color: #16a085;
}
.theme-light .toggle .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track,
.theme-light .toggle .react-toggle--checked .react-toggle-track {
  background-color: #16a085;
}
.theme-contrast .toggle .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track,
.theme-contrast .toggle .react-toggle--checked .react-toggle-track {
  background-color: #16a085;
}
.toggle .react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.toggle .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

