@use "@edgetier/client-components/dist/styles/variables";

.page-content {
    flex: 1;
    padding: variables.$page-spacing;
    overflow-y: auto;

    &__container {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
    }

    &__container--standard {
        max-width: variables.$standard-page-width;
    }
}
