.theme-dark .toggle__state--disabled {
  color: #95a5a6;
}
.theme-light .toggle__state--disabled {
  color: #7f8c8d;
}
.theme-contrast .toggle__state--disabled {
  color: #5a6465;
}

