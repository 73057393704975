.tooltip-warning__content {
  padding: 0 10px;
  border-radius: 4px;
  white-space: nowrap;
  font-weight: bold;
}
.tooltip-warning__content:first-letter {
  text-transform: uppercase;
}
.tooltip-warning__icon {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.tooltip-warning__icon, .tooltip-warning__content {
  background-color: #fbdedb;
  color: #e74c3c;
  border: 1px solid #e74c3c;
}
.tooltip-warning__icon:hover, .tooltip-warning__content:hover {
  background-color: #f8c9c4;
}
.tooltip-warning__icon ul, .tooltip-warning__content ul {
  max-width: 300px;
}
.tooltip-warning__icon ul svg, .tooltip-warning__content ul svg {
  margin-right: 8px;
}
.tooltip-warning__icon ul li, .tooltip-warning__content ul li {
  word-wrap: break-word;
  white-space: normal;
}
.tooltip-warning__icon__warning, .tooltip-warning__content__warning {
  display: flex;
  margin: 10px 0;
  align-items: center;
}

