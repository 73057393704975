.array-details-section:first-of-type {
  margin-top: 4px;
}
.array-details-section + .array-details-section {
  border-top: 1px solid #f2f5f5;
}
.array-details-section .accordion__accordion-item-header__title {
  display: flex;
  justify-content: space-between;
}
.array-details-section .tooltip-warning__trigger {
  margin-left: auto;
  margin-right: 10px;
}

