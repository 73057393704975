/*
  Mixin for applying ellipsis to any element
*/
:root {
  --HEADER-HEIGHT: 55px;
}
@media (min-width: 1600px) {
  :root {
    --HEADER-HEIGHT: 65px;
  }
}

.page-header {
  padding-left: 16px;
  padding-right: 16px;
  border-bottom: 1px solid #dde4e6;
  display: flex;
  width: 100%;
  align-items: center;
  height: var(--HEADER-HEIGHT);
  gap: 12px;
}
.page-header__title {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 0;
}
.page-header h2 {
  margin-right: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.page-header h3 {
  margin-right: auto;
  color: #7f8c8d;
  font-size: 14px;
  font-weight: 700;
}

