@import "styles/spacing";
@import "styles/theme";
@import "styles/spacing";

.assignment-details-field {
    &__text {
        margin-left: $spacing-large;
        word-break: break-word;
    }

    &__date {
        font-weight: 600;
        .details-field-title {
            @include theme {
                color: get(color);
            }
        }
    }

    &__status {
        @include theme {
            color: get(neutral);
        }
        &--active {
            @include theme {
                color: get(positive);
            }
        }
    }
}
