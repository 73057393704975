@use "jeet/jeet";
@use "styles/colours";
@use "styles/variables";
@use "styles/spacing";

@mixin modal($width) {
    width: $width;
    transition: width 250ms ease-out, height 250ms linear;
}

.old-modal {
    $modal-padding: spacing.$spacing-xx-large;
    @include modal(500px);
    box-shadow: 0 10px 20px rgba(black, 0.2), 0 10px 200px rgba(colours.$flat-midnight-blue, 0.5);
    border-radius: 8px;
    opacity: 1;
    transform: scale3d(1, 1, 1);
    animation-duration: 0.45s;
    animation-play-state: running;
    animation-direction: reverse;
    animation-name: appear-up;

    &:focus {
        outline: none;
    }

    &__outer:focus {
        outline: none;
    }

    &__overlay {
        -webkit-overflow-scrolling: touch;
        animation: appear 250ms linear;
        overflow-y: hidden;
        position: absolute;
        display: flex;
        top: 0;
        right: 0;
        left: 0;

        &:not(.old-modal__overlay--drawer) {
            align-items: center;
            justify-content: center;

            .old-modal {
                transform-origin: center;
            }

            .button:not(.button--no-modal),
            .button--split {
                float: right;
                margin-left: spacing.$spacing-x-small;
            }

            .old-modal__content {
                @include jeet.clearfix();
                padding: $modal-padding;
            }

            .button:not(.button--no-modal):not([type="submit"]) {
                margin-top: spacing.$spacing-xx-large;
            }
        }

        &--drawer {
            align-items: flex-end;

            .old-modal__content {
                padding: spacing.$spacing-x-large;
            }

            .old-modal__close {
                display: none;
            }

            .old-modal {
                transform-origin: bottom;
                animation-name: appear-drawer;
            }
        }
    }

    &-wide {
        @include modal(720px);
    }

    &-widest {
        @include modal(1000px);
    }

    &-super-wide {
        @include modal(1200px);
    }

    &__close {
        float: right;
        font-size: 1.8rem;
        cursor: pointer;
    }

    form {
        margin-top: spacing.$spacing-x-large;
        margin-left: -#{$modal-padding};
        margin-right: -#{$modal-padding};
        padding: $modal-padding;

        button[type="submit"],
        .button--split--submit {
            margin-top: $modal-padding * 2;
        }
    }

    h1,
    h2,
    h3 {
        line-height: variables.$heading-line-height;
    }

    h4 {
        line-height: 35px;
    }
}

@keyframes appear-up {
    to {
        opacity: 0;
        transform: scale3d(0.85, 0.85, 1);
    }
}

@keyframes appear-drawer {
    to {
        opacity: 0;
        transform: scale3d(1, 0, 1);
    }
}
