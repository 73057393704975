.grid__menu-section {
  display: flex;
  flex-direction: column;
}
.grid__menu-section + .grid__menu-section {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #dde4e6;
}

