@use "@edgetier/client-components/dist/styles/variables";

.select-preview {
    overflow-x: hidden;
    text-overflow: ellipsis;
    position: relative;
    height: 100%;
    flex: 1;

    &__tooltip {
        line-height: variables.$line-height-base;
    }
}
